@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f0e7d8;
    color: black;
    text-align: center;
    font-family: Poiret One;
    font-weight: bold;
}

.footer {
  font-size: 1rem;
}

@media (max-width: 769px) {
  .footer {
    font-size: 0.8rem;
  }
}

canvas {
    border: none;
    padding: 0;
    margin: 0;
    transform: none;
  }

  /* Underline effect for buttons */
  .underlineCustom::after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 40px;
    background-color: black;
}

.underlineCustom2::after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 30px;
    background-color: black;
}

/* Shadow Effect */
.imageWrapper {
    box-shadow: 0 0.2rem 4rem 2rem rgb(0 0 0 / 0.2);
  }

/* ///////////////////////////////////// */
/* Video */
  .videoStyle {
    width: 100%;
    object-fit: cover;
    max-height: 80vh;
    filter: grayscale(100%) contrast(170%) brightness(90%);
    opacity: 0.4;
    margin-top: 6rem;
}

/* Mobile */
@media (max-width: 769px) {
    .videoStyle {
      max-height: 60vh; 
    }
}
/* Laptop */
@media (min-width: 1024px) {
    .videoStyle {
        object-position: center -90rem;
    }
}

@media (min-width: 770px) and (max-width: 1201px) {
    .videoStyle {
        max-height: 60vh; 
        object-position: center center; 
        
    }
}

@media (min-width: 1025px) and (max-width: 1366px) {
    .videoStyle {
        object-position: center -60rem;
    }
}

@media screen and (min-width: 1202px) and (max-width: 1440px) {
    .videoStyle {
      object-position: center -55rem;
    }
  }

  @media screen and (min-width: 1629px) {
    .videoStyle {
      object-position: center -90rem; 
    }
  }
/* ///////////////////////////////////// */
